import React, {useEffect, useState} from 'react';
import Info from "assets/UserAssets/info.svg";
import EmptyInbox from "assets/UserAssets/emptyInbox.svg";
import PlacesAutocomplete from 'react-places-autocomplete';
import ViewContainer from "components/ViewContainer";
import ContentCol from "components/ContentCol";
import {useDispatch} from "useDispatch";
import SessionControllerAction from "store/sessionController/SessionControllerAction";
import clsx from "clsx";
import {useTypedSelector} from "helpers/reducers";
import PurposeItemContainer from "pages/purpose/components/PurposeItemContainer";
import PurposeItem from "pages/purpose/components/PurposeItem";
import True from 'assets/UserAssets/true.svg'
import {SessionControllerState} from "store/sessionController/SessionControllerReducer";

const Address = props => {
    const dispatch = useDispatch();
    const address = useTypedSelector(state => state.sessionController.user?.address);
    const mailingAddress = useTypedSelector(state => state.sessionController.mailingAddress?.has_mailing_address);
    const sessionController: SessionControllerState = useTypedSelector(state => state.sessionController.mailingAddress);
    const [activeOptionsValue, setActiveOptionsValue] = useState("");
    const [addressGoogle, setAddressGoogle] = React.useState("");
    const searchOptions = {
        componentRestrictions: {country: "cz"}
    };

    useEffect(() => {
        if (mailingAddress !== null) {
            if (mailingAddress) {
                setActiveOptionsValue("Ano")
            } else {
                setActiveOptionsValue("Ne")
            }
        }
    }, []);

    useEffect(() => {
        const splitAddress = addressGoogle.split(',');
        const city = splitAddress[1];
        const splitStreetAndHouseNumberAddress = splitAddress[0].split(" ");
        const houseNumber = splitStreetAndHouseNumberAddress.splice(splitStreetAndHouseNumberAddress.length - 1);
        const street = splitStreetAndHouseNumberAddress.join(" ");

        dispatch(SessionControllerAction.setUserMailingAddress({name: 'street', value: street}));
        dispatch(SessionControllerAction.setUserMailingAddress({name: 'house_number', value: houseNumber[0]}));
        dispatch(SessionControllerAction.setUserMailingAddress({name: 'city', value: city}));
        dispatch(SessionControllerAction.setUserMailingAddress({name: 'address', value: addressGoogle}));
    }, [addressGoogle]);

    const onQuestionAnswerHandler = (event) => {
        const value = event.target.value === 'Ano';
        dispatch(SessionControllerAction.setUserMailingAddress({
            name: "has_mailing_address",
            value: value
        }))
        setActiveOptionsValue(event.target.value)
    }

    return (
        <ViewContainer>
            <ContentCol lg={6} class={'addressOrder-2'}>
                <form>
                    <ul className="calcSummary-list Question-list Purpose-list">

                        <PurposeItemContainer title={<><b>Poštu mi prosím zasílejte jinam</b> - mám jinou korespondenční adresu</>}>
                            <PurposeItem
                                name={'purpose2'}
                                value={'Ano'}
                                objectItem={'has_mailing_address'}
                                label={'Ano'}
                                activeValue={activeOptionsValue}
                                setActiveValue={setActiveOptionsValue}
                                onClick={onQuestionAnswerHandler}/>
                            <div className={clsx("otherInputWrap", mailingAddress && "show")}>
                                <PlacesAutocomplete
                                    value={addressGoogle}
                                    onChange={setAddressGoogle}
                                    searchOptions={searchOptions}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div className={"address__input"}>
                                            <input {...getInputProps()} defaultValue={sessionController.mailingAddress?.address}/>
                                            <div className={"error"}>
                                                Zadejte adresu ve správnem formátu: ulice číslo domu, město
                                            </div>
                                            <div className='googleSuggestions'>
                                                {loading ? <div>...Malý moment</div> : null}

                                                {suggestions.map(suggestion => {
                                                    const style = {
                                                        backgroundColor: suggestion.active ? "#DFF7FF" : "#fff",
                                                        padding: '10px 15px',
                                                        borderLeft: '1px solid #CCDFE5',
                                                        borderRight: '1px solid #CCDFE5',
                                                        borderBottom: '1px solid #CCDFE5'
                                                    };

                                                    return (
                                                        <div {...getSuggestionItemProps(suggestion, { style })}>
                                                            {suggestion.description}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>
                            <PurposeItem
                                name={'purpose2'}
                                value={'Ne'}
                                objectItem={'has_mailing_address'}
                                label={'Ne'}
                                activeValue={activeOptionsValue}
                                setActiveValue={setActiveOptionsValue}
                                onClick={onQuestionAnswerHandler}/>
                        </PurposeItemContainer>
                    </ul>
                </form>
                <div className="loanSummary-info address-info d-flex">
                    <img src={Info} className="notification-icon" alt=""/>
                    <p className="notification-text">
                        <b>Patříme k 10 nejinovativnějším aplikacím</b> českého internetu v roce 2020 v oblasti financí
                    </p>
                </div>
            </ContentCol>
            <ContentCol lg={4} class={'addressOrder-1'}>
                <img src={EmptyInbox} className="empty-inbox DesktopOnly" alt=""/>
                <div className="calcSummary mt-0 ml-0" style={{zIndex: 2, position: 'relative'}}>
                    <h3 className="calcSummary-blue">Adresa pro zasílání pošty je:</h3>
                    <div className="d-flex calcSummary-listItem calcSummary-listItem1" style={{borderTop: '1px solid rgba(204, 223, 229, 0.8)', borderBottom: "none"}}>
                        <img src={True} className={'trueIcon'} alt=""/>
                        <span className="text-bold text-left">{
                            mailingAddress
                                ? addressGoogle
                                : address?.street
                            + " " + address?.house_number
                            + ", " + address?.city
                            + ", " + address?.postal_code}
                        </span>
                    </div>
                </div>
            </ContentCol>
        </ViewContainer>
    );
};

export default Address;