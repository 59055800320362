import React from "react";
import { NavLink } from 'react-router-dom';
import 'components/UserComponents/Logo/Logo.scss';
import LogoImg from 'assets/logo.svg';
import {useDispatch} from "useDispatch";
import SessionControllerAction from "store/sessionController/SessionControllerAction";
import {resetAppState} from "helpers/conifg";
import {useTypedSelector} from "helpers/reducers";
import {SessionControllerState} from "store/sessionController/SessionControllerReducer";
import {LoanReducerState} from "store/loan/LoanReducer";
import {RouteComponentProps, useLocation, withRouter} from "react-router";
import LinkTo from "helpers/LinkTo";
import AppControllerAction from "store/appControler/AppControllerAction";

interface Params {}

interface LogoProps extends RouteComponentProps<Params>{
    closeDrawer: () => void
}

const Logo = (props: LogoProps) => {
    const appController = useTypedSelector(state => state.appController);
    const sessionController: SessionControllerState = useTypedSelector(state => state.sessionController);
    const loanController: LoanReducerState = useTypedSelector(state => state.loanController);
    const location = useLocation();
    const dispatch = useDispatch();
    const onReturnToStart = () => {
        //@ts-ignore
        const state = JSON.parse(localStorage.getItem("state"));
        const cookies = state.appController.cookies
        resetAppState(appController, loanController, sessionController, props.history, location, dispatch);
        dispatch(AppControllerAction.setCookies(cookies));

        props.history.push(LinkTo.home());

        dispatch(SessionControllerAction.resetSessionData());
        props.closeDrawer();
    }

    return (
        <div className="Logo" data-aos="zoom-in">
            <div className="home-link" onClick={onReturnToStart}>
                <img src={LogoImg} alt="VistaCredit logo"/>
            </div>
        </div>
    );
};

export default withRouter(Logo);