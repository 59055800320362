import React, {useEffect, useState} from 'react';
import Keys from "assets/UserAssets/keys2.svg";
import ViewContainer from "components/ViewContainer";
import ImageCol from "components/ImageCol";
import ContentCol from "components/ContentCol";
import {useForm} from "react-hook-form";
import InputElement from "components/InputElement";
import {useDispatch} from "useDispatch";
import SessionControllerAction from "store/sessionController/SessionControllerAction";
import {verifyPassword} from "shared/utility";
import {PassMessages} from "types/entities";
import {Col} from "react-bootstrap";
import {useTypedSelector} from "helpers/reducers";

interface Props {

}

const Registration = (props: Props) => {
    const { register, handleSubmit,setValue, watch, setError, formState: { errors } } = useForm();
    const verifyUserEmail = useTypedSelector(state => state.sessionController.verifyUserEmail);

    const [errorMessage, setErrorMessage] = useState({type: '', message: ''});
    const [errorPhone, setErrorPhone] = useState({type: '', message: ''});
    const [passError, setPassError] = useState(null);
    const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegExp = /^([+]?\d{1,3}[. \s]?)?(\d{9}?)$/;
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");

    const dispatch = useDispatch();

    useEffect(() => {
        // if email is in complete form
        if (emailRegExp.test(email)) {
            // verify if email already exists
                dispatch(SessionControllerAction.verifyUserEmail(email)).then(r => {
                    if (!r.data.exists) {
                        setErrorMessage({type: '', message: ''})
                        dispatch(SessionControllerAction.setUserAccount({name: 'email', value: email}))
                    } else {
                        setErrorMessage({type: 'email', message: 'Tento email je již registrovaný'})
                    }
                });
        } else {
            if (email.length !== 0) {
                setErrorMessage({type: 'email', message: 'Zadejte email ve správnem formátě'})
                dispatch(SessionControllerAction.setUserAccount({name: 'email', value: null}))

            } else {
                setErrorMessage({type: '', message: ''})
            }
        }
    }, [email]);

    useEffect(() => {
        const passResult: PassMessages  = verifyPassword(password, passwordRepeat);
        if (!passResult && passResult.error_type === 'match') {
            setPassError(passResult);
        } else if (!passResult && passResult.error_type === 'length') {
            setPassError(passResult);
        } else {
            setPassError(passResult);
        }
    }, [password, passwordRepeat]);

    useEffect(() => {
        if (phone.length !== 0) {
            if (phoneRegExp.test(phone)) {
                setErrorPhone({type: '', message: ''})
            } else {
                setErrorPhone({type: 'email', message: 'Zadejte telefon ve správnem formátě'})
                dispatch(SessionControllerAction.setUserAccount({name: 'phone_number', value: null}))

            }
        } else {
            setErrorPhone({type: '', message: ''})
        }


    }, [phone]);

    const emailChangeHandler = (e) => {
        setEmail(e.target.value);
    };

    const phoneNumberChangeHandler = (e) => {
        setPhone(e.target.value);
        dispatch(SessionControllerAction.setUserAccount({name: 'phone_number', value: e.target.value}))
    }

    const passwordChangeHandler = (e) => {
        setPassword(e.target.value);
        dispatch(SessionControllerAction.setUserAccount({name: 'password', value: e.target.value}))
    }

    const passwordRepeatChangeHandler = (e) => {
        setPasswordRepeat(e.target.value);
        dispatch(SessionControllerAction.setUserAccount({name: 'password_repeat', value: e.target.value}))
    }

    return (
        <ViewContainer>
            <ImageCol lg={3} image={Keys}/>
            <ContentCol lg={8}>
                <form>
                    <ul className="calcSummary-list Question-list">
                        <h2 className="main-heading registration-main-heading">Zadejte prosím Vaše telefonní číslo,
                            e-mail a zvolte si heslo. <b>HESLO si dobře zapamatujte</b>,
                            budete je potřebovat pro vstup do Vašeho klientského účtu!</h2>
                        <li className="calcSummary-listItem Question-listItem">
                            <div className="position-relative d-flex flex-wrap justify-content-between mb-4 registration">
                                <Col lg={6}>
                                    <h2><b>Zvolte si e-mail</b> pro vstup do klientského účtu</h2>
                                </Col>
                                <Col lg={6}>
                                    <InputElement register={register} type={'email'} name={'email'} label={''} error={errorMessage} onChange={emailChangeHandler}/>
                                </Col>
                            </div>
                            <div className="position-relative d-flex flex-wrap justify-content-between mb-4 registration">
                                <Col lg={6}>
                                    <h2>Vložte prosím <b>Vaše telefonní číslo</b> pro zaslání SMS kódu</h2>
                                </Col>
                                <Col lg={6}>
                                    <InputElement register={register} name={'phone-number'} label={''} onChange={phoneNumberChangeHandler} error={errorPhone}/>
                                </Col>
                            </div>
                            <div className="position-relative d-flex flex-wrap justify-content-between mb-4 registration">
                                <Col lg={6}>
                                    <h2><b>Zvolte si heslo</b></h2>
                                </Col>
                                <Col lg={6}>
                                    <InputElement register={register} type={'password'} name={'password'} label={''} onChange={passwordChangeHandler}/>
                                </Col>
                            </div>
                            <div className="d-flex flex-wrap justify-content-between mb-0 registration">
                                <Col lg={6}>
                                    <h2>Zadejte <b>heslo znovu</b> - pro jistotu</h2>
                                </Col>
                                <Col lg={6}>
                                    <InputElement register={register} type={'password'} name={'password-repeat'} error={passError} label={''} onChange={passwordRepeatChangeHandler}/>
                                </Col>
                            </div>
                        </li>
                    </ul>
                </form>
            </ContentCol>
        </ViewContainer>
    );
};

export default Registration;