import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import "components/UserComponents/Navigation/Toolbar/Toolbar.scss"
import Logo from "components/Logo";
import Servis from "components/UserComponents/Servis/Servis";
import NavigationItems from "components/UserComponents/Navigation/NavigationItems/NavigationItems";
import DrawerToggler from "components/DrawerToggler";
import Button from "components/Button";
import {useTypedSelector} from "helpers/reducers";
import {AppStatus} from "types/enums/AppStatus";
import {getNumberOfCurrentStep} from "helpers/utils";
import AppControllerAction from "store/appControler/AppControllerAction";
import {AppProgressStatus} from "types/enums/AppProgressStatus";
import {AppCurrentStep} from "types/enums/AppCurrentStep";
import LinkTo from "helpers/LinkTo";
import {useDispatch} from "useDispatch";
import {RouteComponentProps, withRouter} from "react-router";
import {useLocation} from "react-router-dom";
import {Cookies_status} from "types/enums/Cookies_status";
import Loader from "components/Loader";

interface Params {}

interface ToolbarProps extends RouteComponentProps<Params>{
    closeDrawer: () => void,
    drawerToggleClicked: () => void,
    visible: boolean,
}

const Toolbar = (props: ToolbarProps) => {
    const appController = useTypedSelector(state => state.appController);
    const cookies = appController.cookies;
    const dispatch = useDispatch();

    if (!cookies) {
        return <Loader/>
    }

    const onStartBuildLoan = () => {
        dispatch(AppControllerAction.setAppStatus(AppStatus.InProgress));
        dispatch(AppControllerAction.setAppProgressStatus(AppProgressStatus.Request));
        dispatch(AppControllerAction.setCurrentStep(AppCurrentStep.Calculation));
        props.history.push(LinkTo.calculation());
    }
    return (
        <header className="header">
            <Container fluid className="pl-1 pr-1 pt-2 pb-2 pt-sm-4 pb-sm-4 pt-xl-5 pb-xl-5 ">
                <Row className="justify-content-between">
                    <Col xs="6" md="auto" className="d-flex pt-sm-2">
                        <Logo closeDrawer={props.closeDrawer} />
                        <div className="servis-wrap DesktopOnly">
                            <Servis />
                        </div>
                    </Col>
                    <Col xs="6" md="auto" className="d-flex justify-content-end">
                        <nav className="DesktopOnly d-flex">
                            <NavigationItems />
                            {appController.app_status === AppStatus.InProgress
                                ? (
                                    <button className="btn-main hoverNone pointer-events-none">
                                        {'Krok ' + getNumberOfCurrentStep(appController.current_step, appController) + ' z ' + appController.total_steps}
                                    </button>
                                )
                                : (
                                    <>
                                        <Button
                                            class="btn-main hoverNone">
                                            <a href="https://klient.vistacredit.cz/sign-in/?next=/"> Přihlásit se k účtu</a>

                                        </Button>
                                        <Button
                                            class="btn-purple"
                                            dataGTMid="chci-uver"
                                            disabled={cookies.status === Cookies_status.NULL}
                                            onClick={onStartBuildLoan}>
                                            CHCI ÚVĚR
                                        </Button>
                                    </>
                                )
                            }

                        </nav>
                                <DrawerToggler visible={props.visible} clicked={props.drawerToggleClicked} />
                    </Col>
                </Row>
            </Container>
        </header>
    );
};

export default withRouter(Toolbar);