import React from 'react';
import {capitalize} from "shared/utility";
import {vokativ} from "vokativ"
import Button from "components/Button";
import {useDispatch} from "useDispatch";
import LoanAction from "store/loan/LoanAction";
import {PriceListItem} from "types/entities";
import {useTypedSelector} from "helpers/reducers";
import {closeModal} from "store/action";
import {RouteComponentProps, withRouter} from "react-router";
import LinkTo from "helpers/LinkTo";

interface Params {}

interface Props extends RouteComponentProps<Params> {}

const VerificationOfferModal = (props: Props) => {
    const loanController = useTypedSelector(state => state.loanController);
    const name = useTypedSelector(state => state.sessionController.user.first_name);
    const dispatch = useDispatch();

    const loanOfferHandler = () => {
        const priceItem: PriceListItem = loanController.priceList?.find(price => price.amount === 4000);
        const loanPrice = priceItem.loan_prices.find(price => price.days === 28);

        dispatch(LoanAction.setLoanSelection({
            ...loanController.loan.loan_selection,
            selected_days: 28,
            selected_price: 4000,
            loan_price: loanPrice.amount,
            deferral_fee: 0,
            total_loan_price: 4000 + loanPrice.amount
        }));
        dispatch(closeModal());
        props.history.push(LinkTo.purpose());
    }

    return (
        <div className="VerificationOfferModal">
            <h1 className="summary-login-modalHeading">{capitalize(vokativ(name))}, trochu jsme si Vás proťukli :)</h1>
            <div className="text-small">
                <div className="d-block">
                    <p>
                        a jako první úvěr Vám můžeme poskytnout částku ve výši <b>4 000 Kč</b> na <b>28 dní</b>.
                    </p>
                    <p>
                        Asi to není tolik, kolik jste si představoval/a, ale musíte uznat, že se zatím neznáme. <br/> <b>Jakmile si vybudujeme vzájemnou důvěru</b>, poskytneme Vám i <b>vyšší částky</b>.
                    </p>
                    <p>
                        Pokud s návrhem souhlasíte, klikněte na tlačítko POKRAČOVAT, pokud NE, tak okno zavřete křížkém v pravo nahoře.
                    </p>
                </div>
            </div>

            <div className="btnsWrap d-flex flex-column">
                <Button class={"btn btn-purple m-0 mt-4 mb-2 mx-auto"} onClick={loanOfferHandler}>pokračovat</Button>
            </div>
        </div>
    );
};

export default withRouter(VerificationOfferModal);