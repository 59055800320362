import React, {useEffect, useState} from 'react';
import ViewContainer from "components/ViewContainer";
import ImageCol from "components/ImageCol";
import ContentCol from "components/ContentCol";
import ThumbUp from "assets/UserAssets/thumbUp.svg";
import True from "assets/UserAssets/true.svg";
import Info from "assets/UserAssets/info.svg";
import Button from "components/Button";
import {useDispatch} from "useDispatch";
import {openModal} from "store/action";
import FinishVerificationModal from "pages/finish/components/FinishVerificationModal";
import SessionControllerAction from "store/sessionController/SessionControllerAction";
import {useTypedSelector} from "helpers/reducers";

const Finish = props => {
    const sessionController = useTypedSelector(state => state.sessionController);
    const [counter, setCounter] = useState(0);
    const [tmp, setTmp] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [isFetching, setIsFetching] = useState(true);
    const dispatch = useDispatch();
    const disableClose = false;
    const openVerificationModal = () => dispatch(openModal(<FinishVerificationModal counter={counter}/>, {disableClose}))

    useEffect(() => {
        window.scrollTo(0, 0);

        openVerificationModal();
        setCounter(30);
    }, []);

    useEffect(() => {
        if (sessionController.lustrations?.address !== undefined
            && sessionController.lustrations?.insolvency !== undefined
            && sessionController.lustrations?.blacklist !== undefined
            && sessionController.lustrations?.internal !== undefined) {
            setCounter(0);
        }
    }, [counter]);

    useEffect(() => {
        if (counter !== 0) {

            if (counter < 10) {
                // @ts-ignore
                setTmp(0);
            }
            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
           if (isFetching) {
                if (counter === 25 || counter === 19 || counter === 14 || counter === 8) {
                    dispatch(SessionControllerAction.fetchLustrations(sessionController.user.birth_number))
                        .then(r => {
                            if (r.data !== null) {
                                setIsFetching(false);
                                setCounter(0);
                            }
                        })
                }
           }
            if (counter === 4) {
                setDisabled(false);
            }
            if (counter === 0) {
                setCounter(0);
                clearTimeout();
            }
        }
    }, [counter]);

    return (
        <ViewContainer class={"Finish-container"}>
            <ImageCol lg={3} image={ThumbUp}/>
            <ContentCol lg={7}>
                <div className="verifyMessageSuccess">
                    <div className="verifyMessageSuccess-wrap d-flex">
                        <img src={True} alt=""/>
                        <div className="textBlackWrap d-block">
                            <div className="d-flex">
                                <h2>Hotovo!</h2>
                                {/*<p className="text-green">ověření Vaší identity bylo dokončeno a vše je v pořádku</p>*/}
                            </div>
                            <p className="textBlack"><b>Zdá se, že všechno klaplo :-) <br/> Máme všechny potřebné údaje a nyní je budeme zpracovávat!</b></p>
                        </div>
                    </div>
                    <h3>Co bude nyní následovat?</h3>
                    <p className="mt-0">Odešleme Vám SMS a e-mail:</p>
                    <ul className="finishList">
                        <li>s potvrzením o registraci</li>
                        <li>ihned Vám žádost před-schválíme</li>
                    </ul>
                    <div className="d-flex cuountdown">
                        <a rel={'noreferrer'} href={"https://klient.vistacredit.cz/sign-in/?next=/"}>
                            <Button class={"btn btn-purple"}>Přihlásit se k účtu</Button>

                        </a>
                    </div>
                    <ul className="finishList">
                        <li><b>vytvoříme Vám účet</b> ve kterém uvidíte své úvěry, osobní údaje a vše co jste zadali při
                            odeslání žádosti. Pomocí účtu budete pomoci i elektronicky podepisovat smlouvy a pod.</li>
                        <li><b>operátor si ihned převezme Vaši žádost</b> a bude ji zpracovávat - o tom Vás taky budeme informovat a v případě SCHVÁLENÍ ÚVĚRU a po podpisu dokumentů Vám <b>ihned odešleme peníze na účet</b></li>
                    </ul>
                </div>
                <div className="loanSummary-info address-info d-flex mt-4">
                    <img src={Info} className="notification-icon" alt=""/>
                    <p className="notification-text mb-0">
                        <b>O další úvěry už budete žádat přímo ze svých účtu a tento proces již nebudete opakovat.</b>
                    </p>
                </div>
            </ContentCol>
        </ViewContainer>
    );
};

export default Finish;