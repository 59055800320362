import React from 'react';

const UserExists = props => {
    return (
        <div className="summary-loginComponent">
            <p className="summary-blueText">Ale my jsme se už viděli</p>
            <a href="http://devel-klient.vistacredit.cz/sign-in/?next=/"
               className="btn btn-purple summary-btn-purple">
                Přihlásit se k účtu
            </a>
        </div>
    );
};

export default UserExists;