import React, {useEffect, useState} from 'react';
import Celebrate from "assets/UserAssets/celebrate.svg";
import Input from "components/Input";
import {useForm} from "react-hook-form";
import Button from "components/Button";
import ViewContainer from "components/ViewContainer";
import ContentCol from "components/ContentCol";
import ImageCol from "components/ImageCol";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {checkRcAge, testRC} from "shared/utility";
import {useDispatch} from "useDispatch";
import SessionControllerAction from "store/sessionController/SessionControllerAction";
import {getNextStep} from "helpers/utils";
import {useTypedSelector} from "helpers/reducers";
import {RouteComponentProps, withRouter} from "react-router";
import AppControllerAction from "store/appControler/AppControllerAction";
import Loader from "components/Loader";
import {openModal} from "store/action";
import VerificationErrorModal from "pages/verification/components/VerificationErrorModal";
import VerificationByeByeModal from "pages/verification/components/VerificationByeByeModal";
import LinkTo from "helpers/LinkTo";

interface Params {}

interface VerificationProps extends RouteComponentProps<Params>{}

type VerificationInputs = {
    first_name: string,
    last_name: string,
    birth_code: string,
}

const Verification = (props: VerificationProps) => {
    const appController = useTypedSelector(state => state.appController);
    const attempt = appController.attempt.attempt
    const [isLoading, setIsLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const dispatch = useDispatch();
    const openErrorModal = () => dispatch(openModal(<VerificationErrorModal/>, {}));
    const openByeByeModal = () => dispatch(openModal(<VerificationByeByeModal/>, {}));
    const ValidationSchema = yup.object().shape({
        first_name: yup.string().required('Zadejte jméno'),
        last_name: yup.string().required('Zadejte příjmení'),
        birth_code: yup.string().required('Zadejte rodné číslo').nullable(),
    })
    const { register, handleSubmit, setError, formState: { errors } } = useForm<VerificationInputs>({
        resolver: yupResolver(ValidationSchema)
    });

    useEffect(() => {
        const timer = setTimeout(() => {
            if (errors.birth_code) {
                setDisabled(false);
                setRedirect(true);
            }
        }, 3000);

        return () => {
            clearTimeout(timer);
        }

    }, [errors]);

    const onSubmitHandler = data => {
        const regex = /^\d+$/;

        if (/\d/.test(data.first_name) ) {
            setError('first_name', {
                type: "first_name",
                message: 'Do jména předse čísla nepatří?!'
            })
        }

        if (/\d/.test(data.last_name) ) {
            setError('last_name', {
                type: "last_name",
                message: 'Do příjmení předse čísla nepatří?!'
            })
        }

        if (!regex.test(data.birth_code)) {
            setError('birth_code', {
                type: "birth_code",
                message: 'Do rodného čísla předse písmena nepatří?!'
            })
            return;
        }

        if (!testRC(data.birth_code)) {
            setError('birth_code', {
                type: "birth_code",
                message: 'Neplatné rodné číslo!'
            })
            return;
        }

        if (checkRcAge(data.birth_code)) {
            setError('birth_code', {
                type: "birth_code",
                message: 'Je nám líto, ale nesplňujete podmínky pro poskytnutí úvěru!'
            })
            setDisabled(true);
            return;
        }
        setIsLoading(true);
        if (attempt < 2) {
            dispatch(SessionControllerAction.fetchUser(data)).then(r => {

                dispatch(AppControllerAction.setAttempt({name: 'attempt', value: attempt + 1}));
                if (!r.data.is_valid) {
                    openErrorModal();
                    setIsLoading(false)
                    return;
                }

                dispatch(SessionControllerAction.setUserBirthNumber(data.birth_code));
                dispatch(SessionControllerAction.isUserExists(data.birth_code)).then(r => {
                    const nextStep = getNextStep(appController.current_step);
                    dispatch(AppControllerAction.setCurrentStep(nextStep.newStep));
                    dispatch(AppControllerAction.setAttempt({name: 'attempt', value: 0}));
                    props.history.push(nextStep.page);
                    setIsLoading(false)
                });
            }).catch(error => {
                console.log(error)
            });
        } else {
            openByeByeModal();
        }
    }

    return (
        <ViewContainer>
            <ImageCol lg={3} image={Celebrate}/>
            <ContentCol lg={6}>
                <h2 className={'mb-4'}>Zadejte <b>Vaše jméno, příjmení a rodné číslo bez lomítka </b>
                    <br/>pro načtení dat z rejstříku.</h2>
                {isLoading
                    ? (
                        <div className={'text-center'}>
                            <Loader/>
                            <div className="loader-text text-center">Chvíli strpení, už to bude...</div>
                        </div>
                    ) : (
                        <form className="checkForm" onSubmit={handleSubmit(onSubmitHandler)}>
                            <Input register={register} error={errors.first_name} name={'first_name'} label={"Vaše jméno:"}/>
                            <Input register={register} error={errors.last_name} name={'last_name'} label={"Příjmení:"}/>
                            <Input register={register} error={errors.birth_code} name={'birth_code'} label={"Rodné číslo:"}/>
                            {redirect
                                ? (
                                    <Button
                                        type={'button'}
                                        onClick={() => {
                                            props.history.push(LinkTo.verificationFailedCauseAge());
                                        }}
                                        class="btn btn-purple btn-purple-checkForm">
                                        Pokračuj
                                    </Button>
                                ) : (
                                    <Button
                                        type={'submit'}
                                        disabled={disabled}
                                        class="btn btn-purple btn-purple-checkForm">
                                        Potvrdit
                                    </Button>
                                )

                            }

                        </form>
                    )}
            </ContentCol>
        </ViewContainer>
    );
};

export default withRouter(Verification);