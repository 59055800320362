import { TypedUseSelectorHook, useSelector } from "react-redux";
import {combineReducers} from "redux";
import AppControllerReducer from "store/appControler/AppControllerReducer";
import LoanReducer from "store/loan/LoanReducer";
import loanBuilderReducer from "store/reducers/UserReducers/loanBuilder";
import verifyUserReducer from "store/reducers/UserReducers/verifyUser";
import questionsReducer from "store/reducers/UserReducers/questions";
import loanPricesReducer from "store/reducers/UserReducers/loanPrices";
import clientReducer from "store/reducers/ClientReducers/client";
import documentsReducer from "store/reducers/ClientReducers/documents";
import personalDataReducer from "store/reducers/ClientReducers/personalData";
import SessionControllerReducer from "store/sessionController/SessionControllerReducer";
import ModalReducer from "store/modal/ModalReducer";
import ModalAction from "store/modal/ModalAction";

function modal(state: null | any, action: any) {
    if (typeof state === "undefined") {
        return {
            component: null,
        };
    }
    if (action.type === ModalAction.SET_OPEN_MODAL) {
        if (action.toggle) {
            if (state && state.component && state.component === action.component) {
                return {
                    component: null,
                    props: {
                        ...state.props,
                        ...(action.props || {}),
                    },
                    isOpen: !state.isOpen,
                };
            }
        }
        const newState = action.component
            ? {
                isOpen: true,
                ...action,
            }
            : {
                component: null,
                props: {},
            };

        return newState;
    } else {
        return state;
    }
}

const reducers = combineReducers({
    // loanBuilder: loanBuilderReducer,
    // verifyUser: verifyUserReducer,
    // questions: questionsReducer,
    // loanPrices: loanPricesReducer,
    // client: clientReducer,
    // documents: documentsReducer,
    // personalData: personalDataReducer,
    modal,

    appController: new AppControllerReducer().reducer,
    loanController: new LoanReducer().reducer,
    sessionController: new SessionControllerReducer().reducer,
    // modal: new ModalReducer().reducer
});

export type RootState = ReturnType<typeof reducers>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default reducers;