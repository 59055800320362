import React, {useEffect} from "react";

import "containers/UserContainers/Contact/Contact.scss"
import PhoneStanding from "assets/UserAssets/phoneStanding.svg";
import Phone from "assets/UserAssets/phone.svg";
import PhoneBody from 'assets/UserAssets/phoneBody.png';
import ViewContainer from "components/ViewContainer";
import ImageCol from "components/ImageCol";
import ContentCol from "components/ContentCol";
import {useDispatch} from "useDispatch";
import {useForm} from "react-hook-form";
import ContactForm from "pages/contact/components/ContactForm";
import AppControllerAction from "store/appControler/AppControllerAction";
import {RouteComponentProps, withRouter} from "react-router";
import LinkTo from "helpers/LinkTo";
import {useTypedSelector} from "helpers/reducers";
import ContactFormMessage from "pages/contact/components/ContactFormMessage";
import {AppStatus} from "types/enums/AppStatus";
import {AppCurrentStep} from "types/enums/AppCurrentStep";
import clsx from "clsx";
import BackArrow from "assets/UserAssets/backArrow.svg";

interface Params {}

interface Props extends RouteComponentProps<Params>{

}

const Contact = (props: Props) => {
    const dispatch = useDispatch();
    const { register, handleSubmit,setValue, watch, setError, formState: { errors } } = useForm();
    const appController = useTypedSelector(state => state.appController);

    useEffect(() => {
            dispatch(AppControllerAction.setAppStatus(AppStatus.Started));
            dispatch(AppControllerAction.setAppProgressStatus(null));
            dispatch(AppControllerAction.setCurrentStep(AppCurrentStep.Start));
    }, [])


    const sendEmailHandler = data => {
        dispatch(AppControllerAction.sendEmail(data));
    };

    const redirectToHomepage = () => {
        dispatch(AppControllerAction.setEmailResponse(null));
        props.history.push(LinkTo.home());
    }

    return (
        <>
            <ViewContainer class={"Contact-container Contact"}>
                <ImageCol lg={3} image={PhoneStanding}/>

                <ContentCol lg={4} class={"verifyMessageBox"}>
                    <div className="verifyMessageSuccess">
                        <div className="verifyMessageSuccess-wrap Contact">
                            <h2>Kontakt</h2>
                        </div>

                        <div className="contact-wrap d-flex">
                            <img src={Phone} className="phoneIcon" alt=""/>
                            <div className="contactNumber">
                                <a href="tel:+420587407707">+420 587 407 707</a>
                                <span className="small-text text-right d-block">Pondělí - Pátek 9 - 17 hod.</span>
                            </div>
                        </div>

                        <div className={"ico"}>
                            <p className="mb-1">
                                VistaCredit s.r.o. <br/>
                                Sádky 1615/4, Prostějov <br/>
                                IČO: 041 14 060
                            </p>
                            <div className="ico mt-0">
                            <span className="small-text">
                                společnost zapsána v obchodním rejstříku vedeném
                                Okresním soudem v Brně, spisová značka C 88282
                            </span>
                            </div>
                        </div>

                        <div className="ico">
                            <span className="small-text">
                                Společnost VistaCredit, s.r.o. neposkytuje spotřebitelské úvěry podle zákona č. 257/2016 sb. <br/>
                                Úvěry poskytované společností VistaCredit, s.r.o. jsou účelové a jsou určeny pro osoby vlastnící platné
                                živnostenské oprávnění.
                            </span>
                        </div>
                        <div className="ico">
                            <p className="mb-0">FIO banka: 2100806238/2010</p>
                            <span className="small-text">
                                        Variabilní symbol plateb: ID Vašeho úvěru
                                    </span>
                        </div>
                    </div>
                </ContentCol>
                <ContentCol lg={3} class={"mobileBodyWrap"}>
                    <img src={PhoneBody} className="phoneBody" alt=""/>
                    <div className="mobileBody">
                        {appController.emailResponse.success
                            ? <ContactFormMessage onClick={redirectToHomepage}/>
                            : <ContactForm onSubmit={handleSubmit(sendEmailHandler)} register={register}/>
                        }
                    </div>
                </ContentCol>
            </ViewContainer>
            <div
                style={{ position: 'absolute',
                    bottom: '50px'}}
                className={clsx("backArrow desktopOnly-nav backArrow-contact")}>
                <div className={'d-flex'} style={{cursor: 'pointer', maxWidth: "200px"}} onClick={redirectToHomepage}>
                    <img src={BackArrow} style={{height: "24px"}} alt="back arrow"/>
                    <p className={'small-text ml-2'}>Zpět domů</p>
                </div>
            </div>
        </>
    );
};


export default withRouter(Contact);