import AppControllerAction from "store/appControler/AppControllerAction";
import update from 'immutability-helper';
import {AppProgressStatus, AppStatus} from "types/enums";
import {AppCurrentStep} from "types/enums/AppCurrentStep";
import {Attempt, AttemptItem, Espoluprace} from "types/entities";
import {getPrevStep} from "helpers/utils";
import {Cookies} from "types/entities/Cookies";
import {generateCookieId, getCookieCreatedDate} from "shared/utility";
import {Cookies_status} from "types/enums/Cookies_status";

interface AppControllerReducerSetAppStatusAction {
    payload: AppStatus;
    type: typeof AppControllerAction.SET_APP_STATUS;
}

interface AppControllerReducerSetAppProgressStatusAction {
    payload: AppProgressStatus;
    type: typeof AppControllerAction.SET_APP_PROGRESS_STATUS;
}

interface AppControllerReducerSetIsProductionAction {
    payload: boolean;
    type: typeof AppControllerAction.SET_PRODUCTION_FLAG;
}


interface AppControllerReducerSetCurrentStepAction {
    payload: AppCurrentStep,
    type: typeof AppControllerAction.SET_CURRENT_STEP
}

interface AppControllerReducerSetTotalStepsAction {
    payload: number,
    type: typeof AppControllerAction.SET_TOTAL_STEPS
}

interface AppControllerReducerSetAttemptAction {
    payload: AttemptItem,
    type: typeof AppControllerAction.SET_ATTEMPT
}

interface AppControllerReducerSetEmailResponseAction {
    payload: any,
    type: typeof AppControllerAction.SET_EMAIL_RESPONSE
}

interface AppControllerReducerSetCookiesAction {
    payload: Cookies,
    type: typeof AppControllerAction.SET_COOKIES
}

interface AppControllerReducerSetEspolupraceAction {
    payload: Espoluprace,
    type: typeof AppControllerAction.SET_ESPOLUPRACE
}

type AppControllerReducerAction =
    AppControllerReducerSetAppStatusAction
    | AppControllerReducerSetAppProgressStatusAction
    | AppControllerReducerSetCurrentStepAction
    | AppControllerReducerSetTotalStepsAction
    | AppControllerReducerSetAttemptAction
    | AppControllerReducerSetEmailResponseAction
    | AppControllerReducerSetCookiesAction
    | AppControllerReducerSetIsProductionAction
    | AppControllerReducerSetEspolupraceAction;

export interface AppControllerState {
    app_status: AppStatus,
    app_progress_status:  AppProgressStatus,
    is_production: boolean | null
    total_steps: number | null,
    current_step: AppCurrentStep | null,
    previous_step: AppCurrentStep | null,
    attempt: Attempt,
    emailResponse: any,
    cookies: Cookies,
    espoluprace: Espoluprace
}

class AppControllerReducer {
    initialState: AppControllerState = {
        app_status: null,
        app_progress_status:  null,
        is_production: null,
        total_steps: null,
        current_step: null,
        previous_step: null,
        attempt: null,
        emailResponse: {
            success: null
        },
        cookies: {
            tech_cookies: true,
            analytics_cookies: false,
            advert_cookies: false,
            cookie_id: generateCookieId(),
            created_at: getCookieCreatedDate(),
            status: Cookies_status.NULL
        },
        espoluprace: {
            a_aid: null,
            t_id: null,
            origin: 10
        }
    };

    reducer = (state: AppControllerState = this.initialState, action: AppControllerReducerAction): AppControllerState => {
        switch (action.type) {
            case AppControllerAction.SET_APP_STATUS:
                return update(state, {
                   app_status: {
                       $set: action.payload
                   }
                });
            case AppControllerAction.SET_APP_PROGRESS_STATUS:
                return update(state, {
                   app_progress_status: {
                       $set: action.payload
                   }
                });

            case AppControllerAction.SET_CURRENT_STEP:
                const prevStep = getPrevStep(action.payload);
                return update(state, {
                    current_step: {
                        $set: action.payload
                    },
                    previous_step: {
                        $set: prevStep.newStep !== AppCurrentStep.Null ? prevStep.newStep : state.previous_step
                    }
                });

            case AppControllerAction.SET_TOTAL_STEPS:
                return update(state, {
                    total_steps: {
                        $set: action.payload
                    }
                })

            case AppControllerAction.SET_PRODUCTION_FLAG:
                return update(state, {
                    is_production: {
                        $set: action.payload
                    }
                })

            case AppControllerAction.SET_ATTEMPT:
                return {
                    ...state,
                    attempt: {
                        ...state.attempt,
                        [action.payload.name]: action.payload.value
                    }
                }

            case AppControllerAction.SET_EMAIL_RESPONSE:
                return {
                    ...state,
                    emailResponse: {
                        success: action.payload
                    }
                }
            case AppControllerAction.SET_COOKIES:
                return update(state, {
                    cookies: {
                        $set: action.payload
                    }
                });
            case AppControllerAction.SET_ESPOLUPRACE:
                return update(state, {
                    espoluprace: {
                        $set: action.payload
                    }
                });

            default:
                return state;
        }
    }
}

export default AppControllerReducer;