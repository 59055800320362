import React, {useEffect, useState} from 'react';
import Contracter from "assets/UserAssets/contract.svg";
import Repi from "assets/UserAssets/repi.svg";
import ViewContainer from "components/ViewContainer";
import ImageCol from "components/ImageCol";
import ContentCol from "components/ContentCol";
import clsx from "clsx";
import LoanAction from "store/loan/LoanAction";
import {useDispatch} from "useDispatch";

const SendRequest = props => {
    const dispatch = useDispatch();
    const [personalDataLoanChecked, setPersonalDataLoanChecked] = useState(false);
    const [personalDataMarketingChecked, setPersonalDataMarketingChecked] = useState(false);
    const [repiChecked, setRepiChecked] = useState(false);

    const onChangeHandler = (key: string, checkedValue: boolean, setCheckedValue: React.Dispatch<React.SetStateAction<boolean>>) => {
        setCheckedValue(!checkedValue);
        dispatch(LoanAction.setAgreement({name: key, value: !checkedValue}));
    };

    return (
        <ViewContainer>
            <ImageCol lg={3} image={Contracter}/>
            <ContentCol lg={8}>
                <form>
                    <ul className="calcSummary-list Question-list Contract-list">
                        <li className={clsx("calcSummary-listItem Question-listItem", personalDataLoanChecked && "Question-listItem__active")}>
                            <div className="d-flex">
                                <input
                                    type="checkbox"
                                    name="personalData"
                                    className='input'
                                    onChange={() => onChangeHandler("personalData", personalDataLoanChecked, setPersonalDataLoanChecked)}
                                />
                                <h2 className={"checkBox-h2"}>Souhlasím se zpracováním mých <a href="/static/web/pdf/ochrana_osobnich_udaju.pdf" target="_blank" className="linkBlue" rel="noopener noreferrer">osobních údajů</a> pro účel poskytnutí úvěru</h2>
                            </div>
                        </li>
                        <li className={clsx("calcSummary-listItem Question-listItem", personalDataMarketingChecked && "Question-listItem__active")}>
                            <div className="d-flex">
                                <input
                                    type="checkbox"
                                    name="personalDataMarketing"
                                    className='input'
                                    onChange={() => onChangeHandler("personalDataMarketing", personalDataMarketingChecked, setPersonalDataMarketingChecked)}
                                />
                                <h2 className={"checkBox-h2"}>Souhlasím se zpracováním mých <a href="/static/web/pdf/ochrana_osobnich_udaju_web_marketing.pdf" target="_blank" className="linkBlue" rel="noopener noreferrer">osobních údajů</a> pro marketingové účely</h2>
                            </div>
                            <span className="smallText">Nesouhlasím s tím, aby společnost VistaCredit s.r.o. poskytla mé osobní údaje jiné společnosti
                                        za účelem reklamních sdělení a obchodních nabídek. Souhlasím s tím, aby mi byly zasílány emailem
                                        a SMS zprávou informace o chystaných akcích např. ÚVĚR ZDARMA apod.</span>
                        </li>
                        <li className={clsx("calcSummary-listItem Question-listItem", repiChecked && "Question-listItem__active")}>
                            <div className="d-flex">
                                <input
                                    type="checkbox"
                                    name="repi"
                                    className='input'
                                    onChange={() => onChangeHandler("repi", repiChecked, setRepiChecked)}
                                />
                                <h2 className={"checkBox-h2"}>Souhlasím s tím, aby si společnost VistaCredit, s.r.o. vyžádala pozitivních
                                    informace o mé platební morálce z databáze <a href="https://www.repi.cz/" target="_blank" rel="noopener noreferrer">repi.cz</a> a tím mohla kvalitně
                                    posoudit mou platební morálku. </h2>
                            </div>
                            <div className="repiLogo d-flex">
                                <a href="https://www.repi.cz/" target="_blank" rel="noopener noreferrer"> <img src={Repi} alt=""/></a>
                                <a href="/static/web/pdf/memorandum_repi.pdf" target="_blank" className="blueText" rel="noopener noreferrer">Memorandum (PDF)</a>
                            </div>
                        </li>
                    </ul>
                </form>
            </ContentCol>
        </ViewContainer>
    );
};

export default SendRequest;