export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};


export const testRC = (x, age) => {
    let e = new Error();
    let err = 1;
    if(!age) age = 0;
    //91 52 25 01426
    // if (x.length > 10 || x.length < 10) throw err;
    try  {
        if(x.length === 0) return true;
        if(x.length < 9 || x.length === 9) throw err;
        let year = parseInt(x.substr(0, 2), 10);
        let month = parseInt(x.substr(2, 2), 10);
        let day = parseInt( x.substr(4, 2), 10);
        if((x.length === 9) && (year < 54)) return true;
        let c = 0;
        if(x.length === 10) c = parseInt(x.substr(9, 1));
        let m = parseInt(x.substr(0, 9)) % 11;
        if(m === 10) m = 0;
        if(m !== c) throw e;
        year += (year < 54) ? 2000 : 1900;
        if((month > 70) && (year > 2003)) month -= 70;
        else if (month > 50) month -= 50;
        else if ((month > 20) && (year > 2003)) month -= 20;
        let d = new Date();
        if((year + age) > d.getFullYear()) throw err;
        if(month === 0) throw err;
        if(month > 12) throw err;
        if(day === 0) throw err;
        if(day > 31) throw err;
    }
    catch(e) {
        // alert('Zadaný údaj není korektní!');
        //  throw e;
        return false;
    }
    return true;
};
export const testRCAge = (x, age) => {
    let e = new Error();
    let err = 1;
    let year, month, day;
    if(!age) age = 0;
    //91 52 25 01426
    // if (x.length > 10 || x.length < 10) throw err;
    try  {
        if(x.length === 0) return true;
        if(x.length < 9 || x.length === 9) throw err;
        year = parseInt(x.substr(0, 2), 10);
        month = parseInt(x.substr(2, 2), 10);
        day = parseInt( x.substr(4, 2), 10);
        if((x.length === 9) && (year < 54)) return true;
        let c = 0;
        if(x.length === 10) c = parseInt(x.substr(9, 1));
        let m = parseInt(x.substr(0, 9)) % 11;
        if(m === 10) m = 0;
        if(m !== c) throw e;
        year += (year < 54) ? 2000 : 1900;
        if((month > 70) && (year > 2003)) month -= 70;
        else if (month > 50) month -= 50;
        else if ((month > 20) && (year > 2003)) month -= 20;
        let d = new Date();
        if((year + age) > d.getFullYear()) throw err;
        if(month === 0) throw err;
        if(month > 12) throw err;
        if(day === 0) throw err;
        if(day > 31) throw err;
    }
    catch(e) {
        // alert('Zadaný údaj není korektní!');
        //  throw e;
        return false;
    }
    return year;
};

export const checkValidity = ( value, rules ) => {
    let isValid = true;
    const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const textRegExp = /^[abcdefghijklmnopqrstuvxyzABCDEFGHIJKLMNOUQRSTUVWXYZáčďéěíňóřšťúůýžÁČĎÉĚÍŇÓŘŠŤÚÝŽ]+$/;

    if ( !rules ) {
        return true;
    }

    if ( rules.required ) {
        isValid = value.trim() !== '' && isValid;
    }

    if ( rules.onlyText ) {
        if (checkEmptyValidity(value)) {
            isValid = true;
        } else {
            isValid = textRegExp.test(String(value).toLowerCase());
        }
    }

    if ( rules.minLength && value.length < rules.minLength) {
            // isValid = value.length >= rules.minLength && isValid
        return false;
    }

    if ( rules.maxLength && value.length > rules.maxLength) {
        // isValid = value.length <= rules.maxLength && isValid
        return false;
    }

    if( rules.number ) {
        isValid = !isNaN(value) && isValid
    }

    if ( rules.isEmail ) {
        isValid = regExp.test(String(value).toLowerCase());
    }

    if ( rules.validCode ) {
        isValid = testRC(value);
    }

    return isValid;
};

export const checkEmptyValidity = ( value ) => {
    return value.length === 0;
};

export const checkRcAge = ( value ) => {
    const year = testRCAge(value);
    console.log(year)
    return year < 1955;
};

export const getPaymentDate = (defaultDays, daysToAdd) => {
    let today = new Date();
    today.setDate(today.getDate() + (defaultDays + daysToAdd));
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();

   return today = dd + '.' + mm + '.' + yyyy;
};


export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};


export const validVerificationForm = (data) => {

}

export const calc_rpsn = (amount, fee, duration) => {
    return ((((amount + fee) / amount) ** (1 / (duration / 365.0)) - 1) * 100).toFixed(0);
};

export const calc_mpsn = (amount, fee, duration) => {
    return ((((amount + fee) / amount) ** (1 / (duration / 30.0)) - 1) * 100).toFixed(0);
};

export const getDeferralFee = (loan, days, price) => {
    console.log(loan,days,price)
    const priceItem = loan.priceList?.find(item => item.amount === price);
    const fee = priceItem.deferral_prices?.find(def => def.days === days - 35);
    console.log(fee);
    return fee?.amount;
}

export const getQuestionType = (question, state) => {
    switch (question) {
        case 'has_execution':
            return state?.has_execution;
        case 'has_office_address':
            return state?.has_office_address;
        case 'has_insolvency':
            return state?.has_insolvency;
        default:
            return undefined;
    }
}

export const verifyPassword = (password, confirmPassword) => {
    if (password !== confirmPassword) {
        if (password.length < 8 && password.length !== 0) {
            return {
                status: false,
                error_type: 'length',
                message: 'Heslo musí obsahovat minimálně 8 znaků.'
            }
        } else {
            return {
                status: false,
                error_type: 'match',
                message: 'Heslo se nezhoduje!'
            };
        }
    } else {
        return {
            status: true,
            error_type: null,
            message: ''
        };
    }
}

export const generateCookieId = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
}

export const getCookieCreatedDate = () => {
    let date = new Date();
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0');
    let yyyy = date.getFullYear();

    return dd + '.' + mm + '.' + yyyy + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
}