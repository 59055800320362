import {createAction} from "helpers/createAction";
import {AppProgressStatus, AppStatus} from "types/enums";
import {AppCurrentStep} from "types/enums/AppCurrentStep";
import {AttemptItem, Espoluprace} from "types/entities";
import ajax from "helpers/ajax";
import {Cookies} from "types/entities/Cookies";

class AppControllerAction {
    static readonly SET_APP_STATUS = "AppControllerAction.SetAppStatus";
    static readonly SET_APP_PROGRESS_STATUS = "AppControllerAction.SetAppProgressStatus";
    static readonly SET_PRODUCTION_FLAG = "AppControllerAction.SetProductionFlag";
    static readonly SET_TOTAL_STEPS = "AppControllerAction.SetTotalSteps";
    static readonly SET_ATTEMPT = "AppControllerAction.SetAttempt";
    static readonly SET_CURRENT_STEP = "AppControllerAction.SetCurrentStep";
    static readonly SET_EMAIL_RESPONSE = "AppControllerAction.SetEmailResponse";
    static readonly SET_COOKIES = "AppControllerAction.SetCookies";
    static readonly SET_ESPOLUPRACE = "AppControllerAction.SetEspoluprace";

    static sendEmail = (data) => {
        return dispatch => {
            return ajax.post("/api/send-mail/", data).then(r => {
                dispatch(AppControllerAction.setEmailResponse(r.data));
                return r;
            });
        }
    }

    static sendCookies = (cookies: Cookies) => {
        return dispatch => {
            return ajax.post('/api/cookies/', cookies).then(r => {
                console.log(r);
                return r;
            });
        }
    }

    static setEspoluprace = (espolupraceData: Espoluprace) => {
        return createAction(AppControllerAction.SET_ESPOLUPRACE, espolupraceData);
    };

    static setCookies = (cookies) => {
        return createAction(AppControllerAction.SET_COOKIES, cookies)
    }

    static setEmailResponse = (emailResponse) => {
        return createAction(AppControllerAction.SET_EMAIL_RESPONSE, emailResponse);
    }

    static setAppStatus = (appStatus: AppStatus) => {
        return createAction(AppControllerAction.SET_APP_STATUS, appStatus);
    }

    static setAppProgressStatus = (appProgressStatus: AppProgressStatus) => {
        return createAction(AppControllerAction.SET_APP_PROGRESS_STATUS, appProgressStatus);
    }

    static setIsProduction = (flag: boolean) => {
        return createAction(AppControllerAction.SET_PRODUCTION_FLAG, flag);
    }

    static setTotalSteps = (totalSteps: number) => {
        return createAction(AppControllerAction.SET_TOTAL_STEPS, totalSteps);
    }

    static setCurrentStep = (currentStep: AppCurrentStep) => {
        return createAction(AppControllerAction.SET_CURRENT_STEP, currentStep);
    }

    static setAttempt = (attempt: AttemptItem) => {
        return createAction(AppControllerAction.SET_ATTEMPT, attempt)
    }
}

export default AppControllerAction;