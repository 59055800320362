import React from 'react';
import clsx from "clsx";

interface Props {
    register: any,
    error?: any,
    type?: string,
    name: string,
    label: any,
    onChange?: (e) => void
}

const InputElement = (props: Props) => {
    return (
        <div className="input">
            <input
                {...props.register(props.name)}
                type={props.type}
                name={props.name}
                onChange={props.onChange}
                className={clsx("inputElement", props.error?.message && 'error-border')}/>
            <div className={clsx("error", props.error ? 'error-show' : "pointer-events-none")}>{props.error?.message}</div>
        </div>
    );
};

export default InputElement;