import React, {useEffect, useState} from "react";

import './NavigationItems.scss';
import {RouteComponentProps, withRouter} from "react-router";
import AppControllerAction from "store/appControler/AppControllerAction";
import {AppProgressStatus} from "types/enums/AppProgressStatus";
import {AppStatus} from "types/enums/AppStatus";
import {AppCurrentStep} from "types/enums/AppCurrentStep";
import {useDispatch} from "useDispatch";
import NavigationItem from 'components/UserComponents/Navigation/NavigationItems/NavigationItem/NavigationItem';
import LinkTo from "helpers/LinkTo";
import Youtube from "assets/socialIcons/youtube.svg"
import Facebook from "assets/socialIcons/facebook.svg"

interface Params {}

interface Props extends RouteComponentProps<Params>{
    onClose?: () => void
}

const NavigationItems = (props: Props) => {
    const dispatch = useDispatch();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [show, setShow] = useState(false);
    const [collapse, setCollapse] = useState('');

    const updateWidthAndHeight = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", updateWidthAndHeight);
        setShow(windowWidth <= 992);
    }, [windowWidth]);

    const showHandler = () => {
        setCollapse('showCollapse');
    };
    useEffect(() => {
        if (props.onClose) {
            setCollapse('');
        }

    }, [props.onClose]);

    const onStartBuildLoan = () => {
        dispatch(AppControllerAction.setAppStatus(AppStatus.InProgress));
        dispatch(AppControllerAction.setAppProgressStatus(AppProgressStatus.Request));
        dispatch(AppControllerAction.setCurrentStep(AppCurrentStep.Calculation));
        props.history.push(LinkTo.calculation());
    }

    const collapseHandler = () => {
        if (show) {
            setCollapse('showCollapse');
        } else {
            setCollapse('');
        }
    }


    // @ts-ignore
    return (
        <ul className="nav d-flex JUSTIFY-CONTENT-END">
            <div className={'li-wrap mobile-only'} style={{borderBottom: 'none'}} onClick={props.onClose}>
                <a rel={'noreferrer'} href="https://klient.vistacredit.cz/sign-in/?next=/" className={"nav-link"}>Přihlásit se k účtu</a>
            </div>
            <div className={'li-wrap'} onClick={props.onClose}>
                <NavigationItem link="/kontakt">Kontakt</NavigationItem>
            </div>
            <li className={"nav-link GDPR " + collapse} onClick={collapseHandler}>
                GDPR
                <div className="submenu">
                    <ul className="gdpr-documents">
                        <li onClick={props.onClose}>
                            <a href="/static/web/pdf/informace_ke_zpracovani_osobnich_dat.pdf" target="_blank" rel="noopener noreferrer">Informace ke zpracování osobních dat</a>
                        </li>
                        <li onClick={props.onClose}>
                            <a href="/static/web/pdf/zadost_o_prenos_osobnich_udaju.pdf" target="_blank" rel="noopener noreferrer">Žádost o přenos osobních údajů</a>
                        </li>
                        <li onClick={props.onClose}>
                            <a href="/static/web/pdf/vymaz_osobnich_udaju.pdf" target="_blank" rel="noopener noreferrer">Výmaz osobních údajů</a>
                        </li>
                    </ul>
                </div>
            </li>
            <div className="social-icons d-flex">
                <li className={"nav-link"}>
                    <a rel={"noreferrer"} href="https://www.facebook.com/VistaCredit.cz">
                        <img src={Facebook} alt="facebook-social-icon"/>
                    </a>
                </li>
                <li className={"nav-link mr-3"}>
                    <a rel={"noreferrer"} href="https://www.youtube.com/channel/UCFpYODdPoVAAHrJ7zGWdFyw">
                        <img src={Youtube} alt="youtube-social-icon"/>
                    </a>
                </li>
            </div>

            <div className={'li-wrap mobile-only border-top-0'} onClick={props.onClose} style={{
                background: "linear-gradient(90deg, #B800F5 0%, #7D00A6 100%)"
            }}>
                <NavigationItem link="/vyber-vyse-uveru" id={'text-white'} click={onStartBuildLoan}>CHCI ÚVĚR</NavigationItem>
            </div>
        </ul>
    );
};

export default withRouter(NavigationItems);
