import React, {useEffect, useState} from 'react';
import {useTypedSelector} from "helpers/reducers";
import Button from "components/Button";
import {useDispatch} from "useDispatch";
import {closeModal} from "store/action";
import AppControllerAction from "store/appControler/AppControllerAction";
import {RouteComponentProps, withRouter} from "react-router";
import LinkTo from "helpers/LinkTo";
import {AppCurrentStep} from "types/enums/AppCurrentStep";
import {AppStatus} from "types/enums/AppStatus";
import {AppProgressStatus} from "types/enums/AppProgressStatus";
import VerificationResultItem from "pages/finish/components/VerificationResultItem";
import {verificationType} from "types/enums/VerificationType";
import Loader from "components/Loader";

interface Params {}

interface Props extends RouteComponentProps<Params> {
    counter: any,
}

const FinishVerificationModal = (props: Props) => {
    const dispatch = useDispatch();
    const lustration = useTypedSelector(state => state.sessionController.lustrations);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (lustration !== {}) {
                setIsButtonDisabled(false)
            }
        }, 5000);

        return () => {
            clearTimeout(timer);
        }
    }, [lustration]);

    const onContinueHandler = () => {
        if (lustration.address === false
            || lustration.insolvency === false
            || lustration.blacklist === false
            || lustration.internal === false) {
            dispatch(closeModal());
            dispatch(AppControllerAction.setCurrentStep(AppCurrentStep.VerificationQuestionsFail));
            dispatch(AppControllerAction.setAppStatus(AppStatus.Error));
            dispatch(AppControllerAction.setAppProgressStatus(AppProgressStatus.Error));
            props.history.push(LinkTo.verificationFailed());
        } else {
            dispatch(closeModal());
        }
    }

    return (
        <div className="FinishVerificationModal">
            <h3 className="Modal-heading mb-5"><b>Důvěřuj, ale prověřuj...</b> se říká. Provedeme nyní rychlou kontrolu základních údajů a zjistíme, jestli společně uzavřeme obchod.</h3>
            <div className="lustrations d-flex flex-column flex-wrap text-left">
                <div className="lustration d-flex flex-column flex-lg-row flex-row justify-content-between">
                    <p>Překontrolujeme naši vnitřní databázi:</p>
                    {lustration?.internal !== undefined
                        ? <VerificationResultItem verificationType={verificationType.DATABASE} result={lustration.internal}/>
                        : <div className="Finish-loader"><Loader /></div>
                    }

                </div>
                <div className="lustration d-flex flex-column flex-lg-row flex-row justify-content-between">
                    <p>Taky se podíváme, jestli u nás už nemáte nějaký škraloup:</p>
                    {lustration?.blacklist !== undefined
                        ? <VerificationResultItem verificationType={verificationType.BLACK_LIST} result={lustration.blacklist}/>
                        : <div className="Finish-loader"><Loader /></div>
                    }
                </div>
                <div className="lustration d-flex flex-column flex-lg-row flex-row justify-content-between">
                    <p>A co insolvence? Nejste v úpadku?:</p>
                    {lustration?.insolvency !== undefined
                        ? <VerificationResultItem verificationType={verificationType.INSOLVENCY} result={lustration.insolvency}/>
                        : <div className="Finish-loader"><Loader /></div>
                    }
                </div>
                {/*<div className="lustration d-flex flex-column flex-lg-row flex-row justify-content-between">*/}
                {/*    <p>Nakonec se mrkneme na adresu vašeho bydlište:</p>*/}
                {/*    {lustration?.address !== undefined*/}
                {/*        ? <VerificationResultItem verificationType={verificationType.ADDRESS} result={lustration.address}/>*/}
                {/*        : <div className="Finish-loader"><Loader /></div>*/}
                {/*    }*/}
                {/*</div>*/}
            </div>
            <button className={"btn btn-purple Modal-btn mt-5 mx-auto"} onClick={onContinueHandler} disabled={isButtonDisabled}>Pokračuj</button>
        </div>
    );
};

export default withRouter(FinishVerificationModal);