import {applyMiddleware, createStore, compose} from "redux";
import reducers from "./reducers";
import {loadState, saveState} from "./state";
import thunk, {ThunkAction} from "redux-thunk";

const APP_VERSION = document.querySelector('meta[name="build-version"]')?.getAttribute("build-version");

if (typeof localStorage.APP_VERSION === 'undefined' || localStorage.APP_VERSION === null) {
    localStorage.setItem('APP_VERSION', APP_VERSION || "unknown");
}

if (localStorage.APP_VERSION && localStorage.APP_VERSION !== "unknown" && localStorage.APP_VERSION != APP_VERSION) {
    localStorage.clear();

    if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
            for (const name of names) {
                caches.delete(name);
            }
        });
    }

    // delete browser cache and hard reload
    window.location.reload(true);
}


const persistedState = loadState();

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, persistedState, composeEnhancers(applyMiddleware(thunk)));

store.subscribe(() => {
    saveState(store.getState());
});

type MyThunkResult<R> = ThunkAction<R, any, any, any>;
export type AppDispatch = typeof store.dispatch | MyThunkResult<Promise<any>>;

export default store;