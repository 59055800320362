import React from 'react';
import {Row} from "react-bootstrap";
import clsx from "clsx";

interface ViewContainerProps {
    children: React.ReactNode | React.ReactNode[],
    class?: string
}

const ViewContainer = (props: ViewContainerProps) => {
    return (
        <Row className={clsx("ViewContainer d-flex align-items-center h-lg-75 pb-3 pb-md-0", props.class)}>
            {props.children}
        </Row>
    );
};

export default ViewContainer;